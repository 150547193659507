<template>
  <div class="common">
    <form @submit.prevent="onSubmit">
      <div class="common-wrapper flex-grow-1">
        <div v-if="isMobileScreenExclude768" class="common-row">
          <div class="common-header clt-container">
            <div class="common-header__title">
              <RouterLink :to="{ name: 'login' }" class="back-btn" />
              <span class="title-text">Reset password</span>
            </div>
          </div>
        </div>

        <div class="common-row">
          <div class="clt-container pb-4">
            <p class="common-text--big">
              Please set your password, we recommend a minimum of {{ $v.password.$params.minLength.min }} characters.
            </p>
          </div>
        </div>

        <div class="common-row">
          <div class="clt-container">
            <div class="common-group">
              <label for="password">Password</label>
              <input
                id="password"
                v-model.trim="password"
                autocomplete="new-password"
                class="font-style"
                :class="{ 'is-invalid': $v.password.$error }"
                type="password"
                @input="passwordError = ''"
                @blur="$v.password.$touch()"
              />
            </div>

            <div v-if="$v.password.$error" class="form-dark-bg-error">
              <small v-if="!$v.password.required" class="form-dark-bg-error__msg">Password is required.</small>
              <small v-else-if="!$v.password.minLength" class="form-dark-bg-error__msg">
                Password must be at least {{ $v.password.$params.minLength.min }} characters.
              </small>
              <small v-else-if="!$v.password.serverFailed" class="form-dark-bg-error__msg">{{ passwordError }}</small>
            </div>
          </div>

          <div class="clt-container">
            <div class="common-group common-group--password-confirm">
              <label for="password_confirmation">Re-type password</label>
              <input
                id="password_confirmation"
                v-model.trim="passwordConfirmation"
                autocomplete="new-password"
                class="font-style"
                :class="{ 'is-invalid': $v.passwordConfirmation.$error }"
                type="password"
                @input="passwordError = ''"
                @blur="$v.passwordConfirmation.$touch()"
              />
            </div>
            <div v-if="!$v.passwordConfirmation.sameAsPassword" class="form-dark-bg-error">
              <small class="form-dark-bg-error__msg">Passwords must be identical.</small>
            </div>
          </div>
        </div>

        <div class="common-row">
          <div class="clt-container">
            <AuthFormCheckbox id="accept" v-model="accept" class="accept-element" @blur="$v.accept.$touch()">
              <template #label>
                To accept our
                <a :href="`${marketingSiteBaseUrl}/legal/privacy-policies`" target="_blank" rel="noopener nofollow"
                  >Privacy Policy</a
                >
                and
                <a :href="`${marketingSiteBaseUrl}/legal/member-agreements`" target="_blank" rel="noopener nofollow"
                  >Member Agreement</a
                >, please click here
              </template>
            </AuthFormCheckbox>
            <div v-if="!$v.accept.isChecked && $v.accept.$dirty" class="form-dark-bg-error">
              <small class="form-dark-bg-error__msg">Acceptance is required.</small>
            </div>
          </div>
        </div>
      </div>

      <div class="common-wrapper">
        <div class="clt-container text-center py-4">
          <button class="common-button" :class="{ 'clt-disabled': $v.$invalid }" :disabled="$v.$invalid" type="submit">
            Continue
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import AppConfig from '@/config';
import apiRequest from '@/api/apiRequest';

import AuthFormCheckbox from '@/components/partials/AuthFormCheckbox';

export default {
  name: 'ResetPasswordPage',
  components: { AuthFormCheckbox },
  metaInfo: {
    title: 'Reset password',
  },
  validations: {
    accept: {
      isChecked: (value) => value === true,
    },
    password: {
      required,
      minLength: minLength(8),
      serverFailed() {
        return !this.passwordError;
      },
    },
    passwordConfirmation: {
      sameAsPassword: sameAs('password'),
    },
  },
  data() {
    return {
      accept: false,
      formSubmitted: false,
      marketingSiteBaseUrl: AppConfig.getAsString('marketingSiteBaseUrl'),
      password: '',
      passwordConfirmation: '',
      passwordError: '',
    };
  },
  computed: {
    ...mapState(['isMobileScreenExclude768']),
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.resetPasswordRequest();
    },
    resetPasswordRequest() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      this.passwordError = '';

      apiRequest({
        url: 'reset-password',
        method: 'post',
        data: {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          token: this.$route.params.token,
          email: this.$route.query.email,
        },
      })
        .then(() => {
          this.password = '';
          this.passwordConfirmation = '';
          this.$v.$reset();
          this.$toast.success('Your password has been changed successfully.');
          this.$router.replace({ name: 'login' });
        })
        .catch(({ response }) => {
          const { status, data: err } = response;
          let error =
            err.message || 'Unfortunately, your password has not been changed. Please contact with the administrator.';

          if (status === 422 && err.errors) {
            error = err.errors.password?.[0] || 'Password validation error.';
            this.passwordError = error;
          }

          this.$toast.error(error);
        })
        .finally(() => {
          this.formSubmitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.common-text--desktop {
  margin-bottom: 0;
  text-transform: initial;

  @media (min-width: 768px) {
    padding: 0 45px;
  }
}
.common-group--password-confirm {
  @media (min-width: 768px) {
    margin-top: 20px;
  }
}

.accept-element {
  margin-top: 30px;
}
</style>
